import axios from 'axios'
import store from '@/store'
import config from '@/config/app.config'
import { Toast } from 'vant'
import i18n from '../i18n'
const requestMsg = i18n.t('requestMsg')
import router from '../router'

axios.defaults.crossDomain = true
axios.defaults.timeout = 8000

const request = (method, url, params, needToken = true, noLoading, loadingIconType, headerContentType = 'application/json', showErrorMessage = true) => {
  const headers = {
    'Content-Type': headerContentType
  }
  headers.lang = store.state.lang || 'ZH'
  if (needToken) { 
    headers.Authorization = store.state.authorized || '' 
  } else {
    headers.Authorization = ''
  }
  if (noLoading) {
  } else {
    store.state.isLoading = true
  }
  if (loadingIconType) {
    store.state.loadingIconType = loadingIconType
  } else {
    store.state.loadingIconType = ''
  }
  return new Promise((resolve, reject) => {
    axios({
      method,
      headers,
      baseURL: config.server,
      url,
      timeout: 15000,
      params: method === 'GET' || method === 'DELETE' ? params : null,
      data: method === 'POST' || method === 'PUT' ? params : null
    }).then(res => {
      setTimeout(() => {
        store.state.isLoading = false
      }, 500)
      Toast.clear()
      if (res.data.code === 422 ) {
        // router.replace('/home')
        localStorage.removeItem('authorized')
        store.commit('SET_TOKEN', '')
        store.commit('SET_IS_LOGIN', 0)
        store.commit('SET_USER_INFO', {})
        store.commit('SET_SHOW_LOGIN_POP', true)
        Toast.fail('Token失效,请重新登录', 1000)
        setTimeout(() => {
          store.state.showLoginPop = true
        }, 1000)
        resolve({ success: false, code: res.data.code, records: [], result: {}, total: 0, message: '登录已失效，请重新登录' })
      } else if (res.data.code === 200) {
        res.data.success = true
        resolve(res.data)
      } else {
        res.data.success = false
        const msg = requestMsg[res.data.message]
        Toast.fail(msg, 5)
        resolve(res.data)
      }
    }).catch(error => {
      Toast.clear()
      let messageText = ''
      if (error.response && error.response.data && error.response.data.message) {
        messageText = error.response.data.message
      } else {
        // messageText = error.response ? getErrorMessage(error.response.status) : 'Network exception'
        messageText = 'Network exception'
      }
      Toast.fail(requestMsg[messageText] || messageText, 5)
      // reject(error)
      resolve({ success: false, records: [], result: {}, total: 0, message: 'Network exception' })
    })
  })
}

export default request
