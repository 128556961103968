import Vue from 'vue'
import VueRouter from 'vue-router'
import gbUtils from '../utils/gbUtils'
import store from '@/store'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      isBack: true,
      notAuth: true
    },
    component: () => import('../views/home/Home.vue')
  },
  {
    path: '/userDetail',
    meta: {
      notAuth: true
    },
    component: () => import('../views/user/UserDetail.vue')
  },
  {
    path: '/personal',
    component: () => import('../views/personal/Personal.vue')
  },
  {
    path: '/personalEdit',
    component: () => import('../views/personal/PersonalEdit.vue'),
    children: [
      {
        path: 'editAddress',
        component: () => import('../views/personal/EditAddress.vue')
      }
    ]
  },
  {
    path: '/invite',
    component: () => import('../views/invite/Invite.vue')
  },
  {
    path: '/withdrawRecord',
    component: () => import('../views/invite/withdrawRecord.vue')
  },
  {
    path: '/agentList',
    component: () => import('../views/agent/AgentList.vue')
  },
  {
    path: '/agentSet',
    component: () => import('../views/agent/AgentDetail.vue')
  },
  {
    path: '/agentAdd',
    meta: {
      title: '经纪人信息',
      keepAlive: false
    },
    component: () => import('../views/agent/AgentAdd.vue'),
    children: [
      {
        path: 'editAddress',
        component: () => import('../views/personal/EditAddress.vue')
      }
    ]
  },
  {
    path: '/agentEdit',
    meta: {
      title: '经纪人信息',
      keepAlive: false
    },
    component: () => import('../views/agent/AgentEdit.vue'),
    children: [
      {
        path: 'editAddress',
        component: () => import('../views/personal/EditAddress.vue')
      }
    ]
  },
  {
    path: '/labelSet',
    component: () => import('../components/user/LabelPop.vue')
  },
  {
    path: '/HwPicker',
    component: () => import('../components/user/HwPicker.vue')
  },
  {
    path: '/editAddress',
    component: () => import('../views/personal/EditAddress.vue')
  },
  {
    path: '/personData',
    component: () => import('../views/user/UserBaseInfo.vue')
  },
  {
    path: '/agentDetail',
    component: () => import('../views/agent/AgentDetail.vue')
  },
  {
    path: '/contact',
    component: () => import('../views/contact/ContactUs.vue')
  },
  {
    path: '/review',
    component: () => import('../views/user/UserReview.vue')
  },
  {
    path: '/editReview',
    component: () => import('../views/user/EditReview.vue')
  },
  {
    path: '/gender',
    component: () => import('../views/user/SelectGender.vue')
  },
  {
    path: '/uploadVideo',
    component: () => import('../views/user/UploadVideo.vue')
  },
  {
    path: '/noticeList',
    meta: {
      notAuth: true
    },
    component: () => import('../views/notice/Notice.vue'),
  },
  {
    path: '/noticeDetail',
    name: 'NoticeDetail',
    meta: {
      notAuth: true
    },
    component: () => import('../views/notice/NoticeDetail.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/feedback/FeedbackAdd.vue')
  }

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.notAuth) {
    next()
  } else if (!gbUtils.isSign()) {
    // Toast.fail(i18n.t('common.unLoginTip'))
    store.state.showLoginPop = true
    next({
      path: '/home',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
