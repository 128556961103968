import Vue from 'vue'
import {
  Toast,
  Button,
  Field,
  Loading,
  Popup,
  Icon,
  List,
  DropdownMenu,
  DropdownItem,
  CountDown,
  Progress,
  Step,
  Steps,
  Uploader,
  Grid,
  GridItem,
  Image as VanImage,
  Picker,
  DatetimePicker,
  ImagePreview,
  Area,
  PullRefresh,
  Overlay,
  Notify,
  ActionSheet,
  Swipe,
  SwipeItem,
  Rate,
  Sticky,
  NoticeBar,
  Tab,
  Tabs,
  RadioGroup,
  Radio
} from 'vant'
Vue.use(Toast)
Vue.use(Notify)
Vue.use(Button)
Vue.use(Field)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(CountDown)
Vue.use(List)
Vue.use(Progress)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Uploader)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Area)
Vue.use(ImagePreview)
Vue.use(PullRefresh)
Vue.use(Overlay)
Vue.use(ActionSheet)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Rate)
Vue.use(Sticky)
Vue.use(NoticeBar)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Radio)
Vue.use(RadioGroup)
