export default {
    changeUserInfo (ctx, userInfo) {
      ctx.commit('SET_USER_INFO', userInfo)
    },
    changeIsLogin (ctx, isLogin) {
      ctx.commit('SET_IS_LOGIN', isLogin)
    },
    changeLang (ctx, lang) {
      ctx.commit('SET_LANG', lang)
    },
    changeLoading (ctx, isLoading) {
      ctx.commit('SET_LOADING', isLoading)
    },
    changeToken (ctx, authorized) {
      ctx.commit('SET_TOKEN', authorized)
    },
    changeShowLoginPop(ctx, isShow) {
      ctx.commit('SET_SHOW_LOGIN_POP', isShow)
    },
    changeShowPersonTipPop(ctx, isShow) {
        ctx.commit('SET_SHOW_PERSON_TIP_POP', isShow)
    },
    clearCacheData(ctx, state) {
      ctx.commit('SET_TOKEN', '')
      ctx.commit('SET_IS_LOGIN', 0)
      ctx.commit('SET_USER_INFO', {})
    },
    logout(ctx, state) {
    },
    changeAreaCode(ctx, areaCode) {
      ctx.commit('SET_AREA_CODE', areaCode)
    }
}
