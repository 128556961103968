import i18n from '../main.js'
import config from '../config/app.config'
const projectName = config.projectName;

export default {
  SET_USER_INFO(state, userInfo) {
    state.userInfo = { ...userInfo };
    if (userInfo) {
      localStorage.setItem(`${projectName}UserInfo`, JSON.stringify(userInfo))
    }
  },
  SET_IS_LOGIN(state, isLogin) {
    state.isLogin = isLogin;
    localStorage.setItem(`${projectName}IsLogin`, isLogin)
  },
  SET_LANG(state, lang) {
    localStorage.setItem(`${projectName}Lang`, lang)
    if (i18n) {
      i18n.locale = lang // i18n语言切换
    }
    state.lang = lang
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_TOKEN(state, authorized) {
    if (!authorized) {
      localStorage.removeItem('authorized')
    } else {
      localStorage.setItem('authorized', authorized)
    }
    state.authorized = authorized
  },
  SET_SHOW_LOGIN_POP(state, isShow) {
    state.showLoginPop = isShow
  },
  SET_SHOW_PERSON_TIP_POP(state, isShow) {
    state.showPersonTipPop = isShow
  },
  SET_HOME_DATA(state, homeData) {
    state.homeData = { ...homeData };
  },
  SET_AREA_CODE(state, areaCode) {
    state.areaCode = areaCode;
    if (areaCode) {
      localStorage.setItem(`${projectName}AreaCode`, areaCode)
    }
  }

}
