import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from 'lodash'
import moment from 'moment'
import i18n from './i18n'
import './plugin/vant'
import VueGtag from 'vue-gtag'
import gbUtils from './utils/gbUtils'
import config from './config/app.config'
// 引入全局样式
import '@/assets/styles/index.scss'

export default i18n

Vue.prototype.$_ = _
Vue.prototype.$moment = moment
Vue.prototype.$gbUtils = gbUtils

Vue.config.productionTip = false

Vue.use(VueGtag, {
  appName: 'maskweb3',
  config: { id: config.gTagId },
  pageTrackerScreenviewEnabled: true,
  pageTrackerTemplate(to) {
    return {
      page_title: to.name || to.path,
      page_path: to.fullPath
    }
  },
  onAfterTrack (to, from) { // 配置路由切换上报数据
   Vue.$gtag.config({
      page_title: to.name || to.path, // 网页的标题
      page_path: to.fullPath // 网页的路径
    })
  }
}, router)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
