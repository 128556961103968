import request from '../utils/request'


/**
 * 注册
 * @param params
 */
export const web2Register = (params) => {
  return request('POST', '/playerLogin/register', params, false, true)
}

/**
 * 登陆
 * @param params
 */
export const web2Login = (params) => {
  return request('POST', '/playerLogin/login', params, false, true)
}

/**
 * 登出
 */
export const web2Logout = () => {
  return request('POST', '/playerLogin/logout', null, true, true)
}


