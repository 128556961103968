<template>
  <div id="app">
    <UserSideBar :userSideShow="userSideShow" @reload="reload" />
    <router-view class="router-view" v-if="isRouterAlive" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UserSideBar from "./components/UserSideBar.vue";
import bus from "./utils/bus";
import "vant/lib/icon/local.css";

export default {
  name: "App",
  components: { UserSideBar },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      sideShow: false,
      userSideShow: false,
      isRouterAlive: true
    };
  },
  computed: {
    isSign() {
      return this.$gbUtils.isSign();
    },
  },
  watch: {
    isSign(val) {
      if (!val) {
        this.$refs.header?.init();
      }
    },
  },
  methods: {
    ...mapActions(["clearCacheData"]),
    changeSide() {
      this.sideShow = !this.sideShow;
    },
    close() {
      this.sideShow = false;
    },
    closeUserSide() {
      this.userSideShow = false;
    },
    clearData() {
      this.clearCacheData();
    },
    // 刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    initPage() {
      this.reload()
    }
  },
  mounted() {
    this.initPage()
  },
  created() {
    bus.$on("showSideBar", () => {
      this.sideShow = !this.sideShow;
    });

    bus.$on("showUserSideBar", () => {
      this.userSideShow = !this.userSideShow;
    });

    bus.$on("closeUserSide", () => {
      this.userSideShow = false;
    });
    bus.$on("loginSuccessReload", () => {
      this.initPage()
    });
  },
  beforeDestroy() {
    bus.$off("showSideBar");
    bus.$off("showUserSideBar");
    bus.$off("closeUserSide");
    bus.$off("loginSuccessReload");
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background-color: $themeBgColor;
}

#app::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
