import Vue from 'vue'
import Vuex from 'vuex'
import config from '../config/app.config'
Vue.use(Vuex)

const projectName = config.projectName

let lang, isLoading, authorized
let showLoginPop = false
let areaCode
let isLogin = 0
let showPersonTipPop = false

let userInfo = {
  userId: '',
  nickName: '',
  headPhoto: '',
  backgroundPhoto: '',
  agent: false
}

try {
  let tempUserInfo = localStorage.getItem(`${projectName}UserInfo`)
  if (tempUserInfo) {
    userInfo = JSON.parse(tempUserInfo)
  }
} catch {}

try {
  let tempIsLogin = localStorage.getItem(`${projectName}IsLogin`)
  if (tempIsLogin) {
    isLogin = parseInt(tempIsLogin)
  }
} catch  {}

try {
  lang = localStorage.getItem(`${projectName}Lang`)
} catch {
  localStorage.setItem(`${projectName}Lang`, 'EN')
  lang = 'EN'
}

try {
  areaCode = localStorage.getItem(`${projectName}AreaCode`)
  if (!areaCode) {
    localStorage.setItem(`${projectName}AreaCode`, 110101)
    areaCode = 110101
  }
} catch {}

try {
  isLoading = false
} catch {
  isLoading = false
}

try {
  authorized = ''
} catch {
  authorized = null
}

const tempAuthorized = localStorage.getItem('authorized')
if (tempAuthorized) {
  authorized = tempAuthorized
}

const localAreaCode = localStorage.getItem(`${projectName}AreaCode`)
if (localAreaCode) {
  areaCode = localAreaCode
}

let homeData = {}

export default {
  lang,
  isLoading,
  authorized,
  homeData,
  showLoginPop,
  areaCode,
  userInfo,
  isLogin,
  showPersonTipPop
}
