import testConfig from './test.config'
import prodConfig from './prod.config'
let config

switch (process.env.RELEASE_ENV) {
    case 'test':
        config = testConfig
        break;
    case 'prod':
        config = prodConfig
        break;
    default:
        console.log('process.env.RELEASE_ENV')
        config = testConfig
        break;
}

const projectName = 'sc'

export default {
    ...config,
    projectName
}
