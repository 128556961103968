<template>
  <div class="mask" :class="[userSideShow ? 'mask-open' : 'mask-close']" @click="close" :key="userSideBarKey">
    <div class="bar-wrapper" @click.stop>
      <!-- 用户信息 -->
      <div class="title">
        <div class="avatar">
          <template v-if="userInfo.headPhoto">
            <img :src="$gbUtils.formatImgUrl(userInfo.headPhoto)" />
          </template>
          <template v-else>
            <img src="@/assets/img/user-default.png" />
          </template>
        </div>
        <div class="nickname">{{ userInfo.nickname }}</div>
        <div class="userId">ID: {{ $gbUtils.formatAddress(userInfo.userId) }} &nbsp;&nbsp;<a @click="handleCopyUserId">复制</a></div>
        <div class="line"></div>
      </div>

      <!-- 菜单列表 -->
      <div class="menu-list">
        <div class="menu-item" v-for="(item, index) in menuList1" :key="index" @click="handleToMenu(item.url)">
          <img class="menu-item-icon" :src="require(`@/assets/img/sidebar/${item.icon}`)" />
          <div class="menu-item-text">{{ formatMenu(item) }}</div>
          <span class="menu-item-language" v-if="item.url === '/languageSwitch'">{{ currentLang }}</span>
          <img v-else class="menu-item-arrow" src="@/assets/img/sidebar/arrow.png" />
        </div>
        <div v-if="userInfo.agent" class="menu-item" @click="handleToMenu('/agentList')">
          <img class="menu-item-icon" src="@/assets/img/sidebar/resource.png" />
          <div class="menu-item-text">{{ lang === 'EN' ? 'Resource' : '资源管理' }}</div>
          <img class="menu-item-arrow" src="@/assets/img/sidebar/arrow.png" />
        </div>
        <div class="menu-item" v-for="(item, index) in menuList2" :key="index + menuList1.length"
          @click="handleToMenu(item.url)">
          <img class="menu-item-icon" :src="require(`@/assets/img/sidebar/${item.icon}`)" />
          <div class="menu-item-text">{{ formatMenu(item) }}</div>
          <span class="menu-item-language" v-if="item.url === '/languageSwitch'">{{ currentLang }}</span>
          <img v-else class="menu-item-arrow" src="@/assets/img/sidebar/arrow.png" />
        </div>

      </div>
      <van-action-sheet v-model="showActions" :actions="actions" @select="onSelectLanguage" />
      <!-- 退出按钮 -->
      <div class="btn" @click="handleLogout">
        <img class="icon" src="@/assets/img/sidebar/logout.png" />
        <span>{{ $t('common.exit') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import bus from '../utils/bus'
import { web2Logout } from '../service/login'
export default {
  name: 'UserSlideBar',
  props: ['userSideShow'],
  inject: ["reload"],
  data() {
    return {
      showActions: false,
      currentLang: '中文',
      actions: [
        { name: '中文', value: 'ZH' }, { name: 'Engilsh', value: 'EN' }
      ],
      userSideBarKey: 0,
      menuList1: [
        {
          "text": "返回首页",
          "enText": "Home",
          "url": "/",
          "icon": "home.png"
        },
        {
          "text": "个人中心",
          "enText": "Profile",
          "url": "/personal",
          "icon": "userinfo.png"
        },
        {
          "text": "邀请推荐",
          "enText": "Invite",
          "url": "/invite",
          "icon": "invite.png"
        }
      ],
      menuList2: [
        {
          "text": "联系我们",
          "enText": "Contact Us",
          "url": "/contact",
          "icon": "call.png"
        },
        {
          "text": "语言切换",
          "enText": "Language Switch",
          "url": "/languageSwitch",
          "icon": "switch.png"
        }
      ]
    }
  },
  computed: {
    ...mapState(['lang', 'userInfo']),
    list() {
      return this.$t('userSideBar')
    }
  },
  methods: {
    ...mapActions(['clearCacheData', 'logout', 'changeLang']),
    onSelectLanguage(e) {
      this.changeLang(e.value);
      this.currentLang = e.name;
      this.showActions = false;
    },
    handleToMenu(url) {
      if (!url) return
      if (url === '/languageSwitch') {
        this.showActions = true;
        return
      }
      this.$emit('close')
      bus.$emit('closeSide')
      this.close()
      this.$gbUtils.handleToPage(url)
    },
    handleCopyUserId() {
      this.$gbUtils.handleCopy(this.userInfo.userId)
      this.close()
    },
    formatMenu(item) {
      if (this.lang === 'EN') return item.enText
      return item.text
    },
    close() {
      bus.$emit('closeUserSide')
    },
    async handleLogout() {
      try {
        //调后端接口登出
        await web2Logout()
      } catch {}
      this.clearCacheData()
      this.$router.replace('/home');
      this.close()
    }
  },
  mounted() {
  },
  created() {
    bus.$on("loginSuccessReload", () => {
    });
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  right: -300px;
  bottom: 0;
  width: 0;
  opacity: 0;
  background-color: rgba(#25262c, 0.7);
  z-index: 9999;
  transition: 0.5s ease;
  display: flex;
  justify-content: flex-end;
}

.mask-open {
  right: 0;
  width: 100%;
  opacity: 1;
}

.mask-close {
  right: -300px;
  opacity: 0;
}

.bar-wrapper {
  width: 200px;
  overflow: hidden;
  height: 100%;
  background-color: #1E232D;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  &.wrapper-open {
    width: 250px;
  }

  &.wrapper-close {
    width: 56px;
    border: none;
  }

  .title {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    width: 100%;

    .avatar {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .nickname {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }

    .userId {
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      margin-top: 5px;
    }

    .line {
      height: 1px;
      background-color: #3d3f51;
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  .menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 0 20px;
    flex: 1;

    .menu-item {
      display: flex;
      align-items: center;
      color: #fff;
      margin-top: 30px;

      .menu-item-icon {
        width: 15px;
        margin-right: 14px;
      }

      .menu-item-text {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
      }

      .menu-item-language {
        min-width: 32px;
        padding: 0 5px;
        height: 20px;
        color: #FD2084;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(253, 32, 132, 0.3);
        font-weight: 600;
        font-size: 10px;
        border-radius: 5px;
      }

      .menu-item-arrow {
        width: 11px;
        height: 11px;
      }
    }
  }

  .btn {
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    box-sizing: border-box;
    background: #2C3341;
    color: #fff;
    display: flex;
    align-items: center;
    // position: absolute;
    // bottom: 54px;
    // left: 0;
    margin-bottom: 20px;

    .icon {
      width: 15px;
      margin-right: 14px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

}

div[class^=menu-item]:hover {
  background: linear-gradient(151deg, #DED5FF 0%, #7530FF, 67%);
}
</style>
