import {setUrlPath} from './index'
import router from '../router'
import {Toast} from 'vant'
import store from '@/store'
import config from '../config/app.config'
import i18n from '../i18n'
import {areaList} from '@/utils/areaData'

const imgServe = config.imgServe

const handleToPage = (url) => {
  if (url) {
    // 跳转
    router.push(url)
  } else {
    router.replace('/')
  }
}

const handleBack = () => {
  router.go(-1)
}

// 钱包地址格式化
const formatAddress = (value) => {
  if (!value) return 0
  const startAddress = value.slice(0, 5)
  const endAddress = value.slice(-5)
  return startAddress + '...' + endAddress
}

// 货币四位小数
const formatBalance = (value) => {
  value = Number(value.toString().match(/^\d+(?:\.\d{0,4})?/))
  return value
}

// 货币两位小数
const formatTwoBalance = (value) => {
  value = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/))
  return value
}

// 都转为小写
const toUpperCase = (value) => {
  return value.toUpperCase()
}

// 都转为小写
const toLowerCase = (value) => {
  return value.toLowerCase()
}

// 格式化距离
const formatDistance = (value) => {
  let distance = ''
  if(value==='' || value===undefined) return distance

  if( value <= 999 ) {
    distance = parseInt(value) + 'm'
  } else {
    distance = parseFloat(value / 1000 ).toFixed(2) + 'km'
  }
  return distance
}

/**
 * 计算活跃时间
 * @param timeStr
 * @param lang
 * @returns {string}
 */
const formatActiveTime2 = (timeStr, lang) => {
  if (!lang) {
    lang = 'ZH'
  }
  let timeObj = new Date(timeStr)

  let currentObj = new Date()
  // 计算时间差 (毫秒为单位)
  let timeDiff = currentObj - timeObj
  if (timeDiff < 0) {
    timeDiff = Math.abs(timeDiff)
  }

  // 定义时间单位的毫秒数
  let minute = 60 * 1000
  let hour = 60 * minute
  let day = 24 * hour
  let month = 30 * day
  let year = 365 * day


  // 根据时间差返回对应文本
  let result = ""
  if (timeDiff < minute) {
    result = Math.floor(timeDiff / 1000 )  + ( lang === 'ZH' ? "秒前" : 'second ago' )
  } else if (timeDiff < hour) {
    result = Math.floor(timeDiff / minute) + ( lang === 'ZH' ? "分钟前" : 'minute ago' )
  } else if (timeDiff < day) {
    result = Math.floor(timeDiff / hour)  + ( lang === 'ZH' ? "小时前" : 'hour ago' )
  } else if (timeDiff < month) {
    result = Math.floor(timeDiff / day)  + ( lang === 'ZH' ? "天前" : 'day ago' )
  } else if (timeDiff < year) {
    result = Math.floor(timeDiff / month) + ( lang === 'ZH' ? "个月前" : 'month ago' )
  } else {
    result = Math.floor(timeDiff / year)  + ( lang === 'ZH' ? "年前" : 'year ago' )
  }
  return result
}

// 格式化最近活跃时间
const formatActiveTime = (uinxtime,lang, ...type) => {
  if (uinxtime === null || uinxtime === undefined || uinxtime === '') return ''
  const currTime = Date.parse(new Date())
  let time = ( (parseInt(currTime) / 1000) - parseInt(uinxtime / 1000) )
  time = Math.abs(time)
  const timeMap = {
    "ZH": {
      1: "刚刚",
      2: "小时前",
      3: "1天前",
      4: "今日活跃",
      5: "1天内"
    },
    "EN": {
      1: "Just Now",
      2: "Hour Ago",
      3: "One Day Ago",
      4: "Active",
      5: "In One Day"
    }
  }
  const timeMap2 = timeMap[lang]
  // 少于一分钟
  if (time < 3600) {
      return timeMap2[1]
  }

  // 秒转分钟
  // let minuies = time / 60;
  // if (minuies < 60) {
  //     return Math.floor(minuies) + "分钟前";
  // }

  // 秒转小时
  let hours = time / 3600;
  if (hours < 24) {
      return Math.floor(hours) + timeMap2[2]
  }
  //秒转天数
  let days = time / 3600 / 24
  if (1< days && days < 2) {
      return timeMap2[3]
  }

  if (type[0] === 'comment') {
    return timeMap2[5]
  }
  return timeMap2[4]
}

// 格式化最近在线时间
const formatOnlineTime = (str,lang) => {
  if (!lang) {
    lang = 'ZH'
  }
  let timeObj = new Date(str)
  // 将时间转换成上海本地时间
  timeObj.setHours(timeObj.getHours() + 8)

  let currentObj = new Date()
  // 计算时间差 (毫秒为单位)
  let timeDiff = currentObj - timeObj
  if (timeDiff < 0) {
    timeDiff = Math.abs(timeDiff)
  }

  // 定义时间单位的毫秒数
  let minute = 60 * 1000
  let hour = 60 * minute
  let day = 24 * hour
  let month = 30 * day
  let year = 365 * day


  // 根据时间差返回对应文本
  let result = ""
  if (timeDiff < minute) {
    result = lang === 'ZH' ? "刚刚" : 'Just Now'
  } else if (timeDiff < hour) {
    result = lang === 'ZH' ? "刚刚" : 'Just Now'
  } else if (timeDiff < day) {
    result = lang === 'ZH' ? "今日活跃" : 'Active'
  } else if (timeDiff < month) {
    result = Math.floor(timeDiff / day) + ' ' + ( lang === 'ZH' ? "天前" : 'day ago' )
  } else if (timeDiff < year) {
    result = Math.floor(timeDiff / month) + ' ' + ( lang === 'ZH' ? "个月前" : 'month ago' )
  } else {
    result = Math.floor(timeDiff / year) + ' ' + ( lang === 'ZH' ? "年前" : 'year ago' )
  }
  return result
}
// 服务器图片URL
const formatImgUrl = (url) => {
  if(url !== null && url !== undefined) {
    const index = url.indexOf('http')
    const firstChar = url.charAt(0)
    if (index === -1 && firstChar === '/') {
      return `${imgServe}${url}`
    }
    if (index === -1 && firstChar !== '/') {
      return `${imgServe}/${url}`
    }
  }
  return  url
}

 // 复制
const handleCopy =  (text) => {
  Toast(i18n.t('tip.copy'))
  const tempInput = document.createElement('input')
  tempInput.style.position = 'absolute'
  tempInput.style.left = '-9999px'
  tempInput.value = text
  document.body.appendChild(tempInput)
  tempInput.select();
  if (document.execCommand('copy')) {
    document.execCommand('copy')
  }
  // 执行浏览器复制命令
  document.body.removeChild(tempInput)
}

const removeImgUrlPrefix = (url) => {
  if(url !== null && url !== undefined) {
    url = url.replace(/^http:\/\/[^/]+/, "")
    url = url.replace(/^https:\/\/[^/]+/, "")
  }
  return url
}

const isSign = () => {
  const state = store.state
  return state.isLogin === 1
}

const isMan = () => {
  const state = store.state
  return state.userInfo.sex === 1
}

/**
 * 根据code获取地区名称
 * @param code
 */
const getAreaNameByCode = (code) => {
  if (!code) return ''
  if (code.endsWith('00')) {
    return areaList.city_list[code]
  } else {
    return areaList.county_list[code]
  }
}

/**
 * 根据code获取市区名称
 * @param code
 * @returns {*|string}
 */
const getCityNameByCode = (code) => {
    if (!code.endsWith('00')) {
        code = code.substring(0,4) + '00'
    }
    const city = areaList.city_list
    if (city) {
        const cityName = city[code]
        if (cityName) {
        return cityName
        }
    }
    return ''
}

const getAreaListByCityCode = (cityCode) => {
  if (!cityCode) return []
  const resultList = []
  const area = areaList.county_list
  if (area) {
    for (let key in area) {
        if (key.startsWith(cityCode)) {
          resultList.push({
                code: key,
                name: area[key]
            })
        }
    }
  }
  return resultList
}

/**
 * 根据code获取省市区名称
 * @param code
 */
const getAllPositionName = (code) => {
  if (!code) return ''
  const provinceCode = code.substring(0,2) + '0000'
  const cityCode = code.substring(0,4) + '00'
  const provinceName = areaList.province_list[provinceCode] ? areaList.province_list[provinceCode] : ''
  const cityName = areaList.city_list[cityCode] ? areaList.city_list[cityCode]: ''
  const areaName = areaList.county_list[code] ? areaList.county_list[code] : ''
  return (provinceName && cityName && areaName) ? provinceName + '/' + cityName + '/' + areaName : ''
}


const strMapToObj = (strMap) => {
  let obj = Object.create(null)
    for (let [k,v] of strMap) {
        obj[k] = v
    }
    return obj
}

const objToStrMap = (obj) => {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
      strMap.set(k, obj[k])
  }
  return strMap
}

const strMapToJson = (strMap) => {
  return JSON.stringify(strMapToObj(strMap))
}

const jsonToStrMap = (jsonStr) => {
  if (jsonStr === null) return new Map()
  return objToStrMap(JSON.parse(jsonStr));
}

const isEmptyString = (str) => {
  return str === null || str === undefined || str === ''
}

const notEmptyString = (str) => {
  return !isEmptyString(str)
}

const getLoginUserId = () => {
  let userId = ''
  if (store.state.userInfo) {
    userId = store.state.userInfo.userId
  }
  return userId
}

const genRandomNum = () =>{
  let randomString = '';
  for (let i = 0; i < 4; i++) {
    const randomDigit = Math.floor(Math.random() * 10); // 生成0到9的随机数字
    randomString += randomDigit.toString(); // 将数字转换为字符串并添加到结果字符串中
  }
  return randomString;
}


const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

const gbUtils = {
  setUrlPath,
  handleToPage,
  handleBack,
  formatBalance,
  formatTwoBalance,
  formatAddress,
  toUpperCase,
  toLowerCase,
  formatDistance,
  formatActiveTime,
  formatImgUrl,
  handleCopy,
  removeImgUrlPrefix,
  isSign,
  strMapToJson,
  jsonToStrMap,
  sleep,
  isEmptyString,
  notEmptyString,
  getAreaNameByCode,
  getAllPositionName,
  formatOnlineTime,
  getCityNameByCode,
  getAreaListByCityCode,
  getLoginUserId,
  formatActiveTime2,
  isMan,
  genRandomNum
}

export default gbUtils
